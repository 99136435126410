import {
  Admin,
  Resource,
  ListGuesser,
  CustomRoutes,
  fetchUtils,
} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { Route } from "react-router-dom";
import authProvider from "./authProvider";
import Prices from "./prices";
import SendSms from "./sendSms";
import LockerStatus from "./lockerStatus";
import LockerPrice from "./lockerPrice";
import Transactions from "./asd";
import simpleRestProvider from "ra-data-simple-rest";
import CheckIn from "./checkin";
import { MyLayout } from '../MyLayout';
import CountryReport from "./CountryChart";
import Locations from "./locations";
import CampaignList from "./CampaignList";
import CouponCreate from "./CouponCreate";
import FileInput from "./FileInput";
import CheckRoom from "./CheckRoom";
import React from "react";
import CheckroomLocations from "./checkroomLocations";


//  const apiUrl = "https://localhost:5001";
const apiUrl = "https://api.easypoint.com.tr";

const dataProvider = simpleRestProvider(apiUrl);
const { http } = dataProvider;


const httpClient = fetchUtils.fetchJson;

const appDataProvider = {
  ...dataProvider,

  async CampaignList() {
    const url = `${apiUrl}/${"campaign"}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },


  async CheckRoomList() {
    const url = `${apiUrl}/${"CheckRoom/get-all-transactions"}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  


  async BoxList() {
    const url = `${apiUrl}/${"boxapi/box-list"}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  async CountryReport() {
    const url = `${apiUrl}/${"boxapi/countryTable"}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json,
    }));
  },

  async SendSms(data: any) {
    const url = `${apiUrl}/${"other-services/box-sms"}`;
    return httpClient(url, {
      method: "POST",
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },

  async CreateCampaign(data: any) {
    const url = `${apiUrl}/${"campaign"}`;
    return httpClient(url, {
      method: "POST",
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },

  async BulkInsert(data: any) {
    const url = `${apiUrl}/${"bulkInsert"}`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },


  async SaveLocationSetting(data: any) {
    const url = `${apiUrl}/${"boxapi/save-location-settings"}`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },

  async getLocationSetting(locationCode: string) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/get-location-setting/${locationCode}`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },




  async checkBox(boxNumber: string, locationCode: string, organisation: string) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/checkBox2/${boxNumber}/${locationCode}/${organisation}`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async checkBoxforBuyTime(boxNumber: string, _orderType: number) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/checkBoxforBuyTime/${boxNumber}`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },


  async getDurations(code:any) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/durations?code=` + code, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async sendOTPCode(model: any) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/verification-code`, {
        method: "POST",
        body: JSON.stringify(model),
      })
      .then(({ json }) => ({
        data: json,
      }));
  },
  async finishBox(transactionId: any) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/finishBox/${transactionId}`, {
        method: "POST",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },





  async verificationLogin(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/verification-login`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },



  async sendOTPCodeBuyTime(model: any) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/verification-code-buytime`, {
        method: "POST",
        body: JSON.stringify(model),
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async verificationLoginBuyTime(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/verification-login-buytime`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },

  async payment(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/box-payment`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },


  async paymentBuyTime(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/boxapi/box-payment-buytime`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },



  async UpdateBoxPrice(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/Box/UpdateBoxPrice`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },


  async getLocations() {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/locations`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },


  async getBoxPrice() {
    return fetchUtils
      .fetchJson(`${apiUrl}/getBoxPrice`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },

  async getLockers(locationCode: string) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/location/${locationCode}/lockers`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },


  async getAdminLockers(locationCode: string) {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/location/${locationCode}/adminlockers`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },


  async getEur() {
    return fetchUtils
      .fetchJson(`${apiUrl}/boxapi/eur`, {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },


  async CheckRoomGetAllPrices() {
    return fetchUtils
      .fetchJson(`${apiUrl}/CheckRoom/get-all-prices` , {
        method: "GET",
      })
      .then(({ json }) => ({
        data: json,
      }));
  },



  async UpdateCheckRoom(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/CheckRoom/update-transaction`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },

  


  async SaveCheckRoomLocationSetting(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/CheckRoom/setPrices`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },


  async ReSend(model: any) {
    try {
      return fetchUtils
        .fetchJson(`${apiUrl}/CheckRoom/re-send`, {
          method: "POST",
          body: JSON.stringify(model),
        })
        .then(({ json }) => ({
          data: json,
        }));
    } catch {
      return { data: { status: false } };
    }
  },




};

const App = () => (
  <Admin dataProvider={appDataProvider} requireAuth authProvider={authProvider} layout={MyLayout}>


{(    localStorage.getItem("username") == "checkroom" || localStorage.getItem("username") == "songulduzgun"  || localStorage.getItem("username") == "fuatyazgan" 
|| localStorage.getItem("username") == "fatihyazici"
|| localStorage.getItem("username") == "asligaygili"
 || localStorage.getItem("username") == "azizepolat"

 ||  (localStorage.getItem("username") == 'ozlemnurcan' ) ||
 (localStorage.getItem("username") == 'busracilembozyel') ||
 (localStorage.getItem("username") == 'semuraeyisarac' ) 
 
) &&
     <React.Fragment>
       <Resource
        options={{ label: "CheckRoom" }}
        name="checkroom"
        list={CheckRoom} 
      />
      <Resource
      options={{ label: "Locations" }}
      name="checkroomLocations"
      list={CheckroomLocations} 
   
    />  </React.Fragment>


    }

{(localStorage.getItem('username') == "admin" || localStorage.getItem('username') == "superadmin"|| localStorage.getItem('username') == "natavega") &&
      <Resource
        options={{ label: "Locker Transactions" }}
        name="transactions"
        list={Transactions}
      />


    }
      <Resource
        options={{ label: "Country Report" }}
        name="transactions"
        list={CountryReport}
      />

    {/* <Resource options={{ label: "Prices" }} name="prices" list={Prices} /> */}
    <Resource options={{ label: "Locker Status" }} name="lockerstatus" list={LockerStatus} />
    <Resource options={{ label: "Campaign List" }} name="campaignlist" list={CampaignList} />

    <Resource options={{ label: "Locations" }} name="locations" list={Locations} />
    <Resource options={{ label: "Create Coupon" }} name="couponCreate" list={CouponCreate} />
    {(localStorage.getItem('username') == "admin" || localStorage.getItem('username') == "superadmin" ) &&
      <Resource options={{ label: "Locker Price" }} name="lockerprice" list={LockerPrice} />
    }
    <Resource options={{ label: "Send SMS" }} name="sendsms" list={Prices} />
    <Resource options={{ label: "Check-in" }} name="checkin" list={CheckIn} />
    <Resource options={{ label: "FileInput" }} name="checkin" list={FileInput} />
    {(localStorage.getItem('username') == "admin" || localStorage.getItem('username') == "superadmin"|| localStorage.getItem('username') == "natavega") &&
      <CustomRoutes>
        <Route path="/transactions" element={<Transactions />} />
      </CustomRoutes>
    }
    {/* <CustomRoutes>
      <Route path="/prices" element={<Prices />} />
    </CustomRoutes> */}
    <CustomRoutes>
    <Route path="/countryReport" element={<CountryReport />} />
      <Route path="/sendsms" element={<SendSms />} />
      <Route path="/lockerstatus" element={<LockerStatus />} />

      <Route path="/checkroom" element={<CheckRoom />} />

      <Route path="/checkroomLocations" element={<CheckroomLocations />} />
      <Route path="/campaigns" element={<CampaignList />} />
      <Route path="/couponCreate" element={<CouponCreate />} />
      {(localStorage.getItem('username') == "admin" || localStorage.getItem('username') == "superadmin") &&
        <Route path="/lockerprice" element={<LockerPrice />} />
      }
      <Route path="/checkin" element={<CheckIn />} />
      <Route path="/FileInput" element={<FileInput />} />
      
    </CustomRoutes>
  </Admin>
);

export default App;
